import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { EVENTS } from "@upsolve/shared";
import { ACSS, H1, H6, P} from "@upsolve/ui";
import { TReviewType } from "@upsolve/shared/dist/types";

import { track } from "../components/analytics/track";
import ratingsJSONLD from "../components/SEO/ratingsJSONLD";
import reviewsJSON from "../../content/onBuild/reviewData.json";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import * as SEARCH_TERM_TARGETS from "../components/analytics/searchTermTargets";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import getFilteredReviewsData from "../components/reviews/getFilteredReviewsData";
import ReviewTile from "../components/reviews/ReviewTile";
import viewReviewUrl from "../components/reviews/viewReviewUrl";
import Portal from "../components/overlays/Portal";
import VideoModal from "../components/overlays/VideoModal";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "Reviews",
  pageComponentVersion: 1,
};
const trackingPageContext = {
  contentGroup,
  contentLocalized: false,
  searchTermTargets: [SEARCH_TERM_TARGETS.UPSOLVE_REVIEWS],
  ...templateProps,
};

/**
 * ReviewsPage
 * - v0: Reviews from Contentful
 * - v1: Reviews from Google & Facebook
 *
 * @component
 * @version 1
 */
const Reviews = (props) => {
  const [reviews, setReviews] = useState([]);
  const [reviewTypeFilter, setReviewTypeFilter] = useState(null);
  const [reviewTextFilter, setReviewTextFilter] = useState(true);
  const [aggregateRating, setAggregateRating] = useState("");
  const [watchUserVideo, setWatchUserVideo] = useState(false);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  function handleReviews() {
    const { reviews, reviewCount, rating } = getFilteredReviewsData(props.data.pictures.nodes);
    setReviews(reviews);
    setTotalReviewCount(reviewCount);
    setAggregateRating(rating);
  }

  // On Mount
  useEffect(() => {
    // - Setup Review Data
    handleReviews();
    // - Page Track
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);

  // Render
  return (
    <TopLevelLayout>
      <div className="index-container">
        <SEO
          title={`Reviews (${aggregateRating}/5) | Upsolve`}
          description="Read reviews about Upsolve’s free debt relief help from people who used Upsolve to take control of their debt."
          image="https://upsolve.org/images/meta-probono.png"
          url="https://upsolve.org/reviews/"
          jsonLD={[ratingsJSONLD]}
        />
        <Header trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />
        <StyledReviewsPage>
          <div className="reviews__lead">
            <div className="reviews__lead__rating">
              {Array(5)
                .fill("★", 0, 5)
                .map((star, starIndex) => (
                  <StyledStar key={starIndex} ratingLevel={starIndex + 1} rating={aggregateRating}>
                    {star}
                  </StyledStar>
                ))}
            </div>
            <H1 as="h1">Upsolve has a {aggregateRating}/5 rating</H1>
            <H6 as="h2">
              Read the{" "}
              <span onClick={() => viewReviewUrl(TReviewType.FACEBOOK)}>
                <u>Facebook</u>
              </span>{" "}
              &{" "}
              <span onClick={() => viewReviewUrl(TReviewType.GOOGLE)}>
                <u>Google</u>
              </span>{" "}
              reviews of {totalReviewCount} members who used Upsolve's tools to overcome their debts.
            </H6>
          </div>
          <div className="reviews__content">
            <div className="reviews__content__controller">
              <div>
                <div className="scores">
                  <div>
                    <div className="score-label">
                      5 <span className="star">★</span>
                    </div>
                    <StyledRatingBar
                      ratingCount={reviewsJSON.filter((r) => r.rating === 5).length}
                      totalRatingCount={reviewsJSON.length}
                    >
                      <span />
                    </StyledRatingBar>
                  </div>
                  <div>
                    <div className="score-label">
                      4 <span className="star">★</span>
                    </div>
                    <StyledRatingBar
                      ratingCount={reviewsJSON.filter((r) => r.rating === 4).length}
                      totalRatingCount={reviewsJSON.length}
                    >
                      <span />
                    </StyledRatingBar>
                  </div>
                  <div>
                    <div className="score-label">
                      3 <span className="star">★</span>
                    </div>
                    <StyledRatingBar
                      ratingCount={reviewsJSON.filter((r) => r.rating === 3).length}
                      totalRatingCount={reviewsJSON.length}
                    >
                      <span />
                    </StyledRatingBar>
                  </div>
                  <div>
                    <div className="score-label">
                      2 <span className="star">★</span>
                    </div>
                    <StyledRatingBar
                      ratingCount={reviewsJSON.filter((r) => r.rating === 2).length}
                      totalRatingCount={reviewsJSON.length}
                    >
                      <span />
                    </StyledRatingBar>
                  </div>
                  <div>
                    <div className="score-label">
                      1 <span className="star">★</span>
                    </div>
                    <StyledRatingBar
                      ratingCount={reviewsJSON.filter((r) => r.rating === 1).length}
                      totalRatingCount={reviewsJSON.length}
                    >
                      <span />
                    </StyledRatingBar>
                  </div>
                </div>
                {/* <Button buttonType="white" onClick={() => setReviewTypeFilter(null)}>
                  View all reviews
                </Button>
                <Button buttonType="white" onClick={() => setReviewTypeFilter(TReviewType.FACEBOOK)}>
                  View Facebook reviews
                </Button>
                <Button buttonType="white" onClick={() => setReviewTypeFilter(TReviewType.GOOGLE)}>
                  View Google reviews
                </Button> */}
                {/* <div>
                  <label>
                    <Input
                      type="checkbox"
                      checked={reviewTextFilter}
                      onChange={() => setReviewTextFilter(!reviewTextFilter)}
                    />{" "}
                    Only see reviews with text
                  </label>
                </div> */}
                <div className="right-from-users">
                  <P>
                    Seems too good to be true?{" "}
                    <span onClick={() => setWatchUserVideo(true)}>
                      <br />
                      Listen to our users' experiences
                    </span>
                  </P>
                  {watchUserVideo && (
                    <Portal>
                      <VideoModal
                        videoUrl="https://www.youtube.com/watch?v=aLr-xJGWX40"
                        onClose={() => setWatchUserVideo(false)}
                      />
                    </Portal>
                  )}
                </div>
              </div>
            </div>
            <div className="reviews__content__list">
              {reviews
                .filter((review) => (reviewTextFilter ? (review.reviewText || "").length : true))
                .filter((review) => (reviewTypeFilter ? review.type === reviewTypeFilter : true))
                .map((review, reviewIndex) => (
                  <ReviewTile key={reviewIndex} review={review} showReviewFully />
                ))}
            </div>
          </div>
          <section>
            <div className="reviews__hero__scene-front" />
            <div className="reviews__hero__scene-back" />
          </section>
        </StyledReviewsPage>
        <Footer />
      </div>
    </TopLevelLayout>
  );
};

const StyledRatingBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 4px;
  margin: auto 0;
  background: ${(props) => props.theme.colors.white[600]};
  span {
    height: 4px;
    border-radius: 2px;
    background: var(--google-review-star-orange);
    ${({ ratingCount, totalRatingCount }) => {
      return css`
        width: ${(ratingCount / totalRatingCount) * 100}%;
      `;
    }}
  }
`;

const StyledStar = styled.div`
  font-size: 32px;
  margin-left: -32px;
  padding-top: 4px;
  height: 40px;
  overflow: hidden;
  // ::after is used for backdrop actually since we can overflow hidden on these
  &::after {
    content: "★";
    z-index: 5;
    left: -32px;
    color: white;
  }
  ${({ rating, ratingLevel }) => {
    // Fill in if above rank
    if (rating > ratingLevel) {
      return css`
        color: var(--google-review-star-orange);
      `;
    }
    // Fill in partially if at rank range
    if (rating < ratingLevel && rating > ratingLevel - 1) {
      return css`
        color: var(--google-review-star-orange);
        width: ${Math.ceil(32 * (rating % 1))}px;
        max-width: ${Math.ceil(32 * (rating % 1))}px;
        position: relative;
        z-index: 6;
        &::after {
          content: "";
        }
      `;
    }
  }}
`;

const StyledReviewsPage = styled.main`
  --google-review-star-orange: #ff863b;
  .reviews__lead,
  .reviews__content {
    width: 100%;
    max-width: 1080px;
  }
  .reviews__lead {
    margin: 6em auto 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 500;
      color: ${(props) => props.theme.colors.gray[900]};
    }
    h1,
    h2 {
      color: ${(props) => props.theme.colors.brand[500]};
    }
    h2,
    h4 {
      margin: 0.5em 0;
      span:hover {
        cursor: pointer;
      }
    }
    .reviews__lead__rating {
      display: flex;
      align-items: center;
      position: relative;
      left: 8px;
      height: 40px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 0 1.5em;
      margin: 2em auto 0;
    }
  }
  .reviews__content {
    display: flex;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    margin: 2em auto;
    .reviews__content__controller {
      min-width: 270px;
      max-width: 270px;
      width: 100%;
      padding: 2em 1em 2em 2em;
      & > div {
        margin-top: 0.75em;
        position: sticky;
        top: 2em;
      }
      .scores {
        margin-bottom: 1em;
        & > div {
          margin-bottom: 0.25em;
          display: grid;
          grid-template-columns: 36px 1fr;
          .score-label {
            display: grid;
            grid-template-columns: 12px 1fr;
            font-weight: 500;
          }
          .star {
            font-size: 12px;
            color: var(--google-review-star-orange);
          }
        }
      }
      button {
        width: 100%;
        margin-bottom: 0.5em;
        font-size: 14px;
        justify-content: flex-start;
      }
      label {
        font-size: 14px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        padding: 1em;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
    .reviews__content__list {
      width: 100%;
      padding: 2em 2em 2em 1em;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        padding: 1em;
      }
    }
  }
  .right-from-users {
    width: 100%;
    p {
      padding: 0;
      color: ${(props) => props.theme.colors.gray[900]};
      span {
        ${ACSS}
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .reviews__hero__scene-front,
  .reviews__hero__scene-back {
    position: fixed;
  }
  .reviews__hero__scene-front {
    top: 40px;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: -1;
    background: url("/images/illustrations/home-cover-front.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
  .reviews__hero__scene-back {
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    max-width: 960px;
    margin-left: auto;
    z-index: -2;
    background: url("/images/illustrations/home-cover-back.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    opacity: 0.7;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    .reviews__content {
      flex-direction: column;
      & > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;

export const pageQuery = graphql`
  query ReviewsListingQuery {
    pictures: allContentfulReview(
      filter: { image: { id: { ne: null } } }
      sort: { fields: [date], order: ASC }
      limit: 14
    ) {
      nodes {
        image {
          altText
          image {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Reviews;
