import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { IconX } from "@upsolve/ui";

import ModalBackground from "./ModalBackground";

const VideoModal = ({ controls = true, onClose, videoUrl }) => {
  return (
    <VideoModalStyled>
      <ModalBackground onClick={() => onClose()}>
        <div className="video-container">
          <div className="video">
            <div className="close">
              <IconX onClick={() => onClose()} />
            </div>
            <ReactPlayer width="100%" height="100%" url={videoUrl} controls={controls} />
          </div>
        </div>
      </ModalBackground>
    </VideoModalStyled>
  );
};

const VideoModalStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  .video-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video {
    width: 80%;
    max-width: 1080%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;
    @media (max-width: 45em) {
      width: 100%;
      height: 35%;
    }
  }
  .close {
    position: fixed;
    top: 18px;
    right: 18px;
    z-index: 100;
    svg {
      height: 36px;
      width: 36px;
      path {
        fill: ${(props) => props.theme.colors.white[900]};
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export default VideoModal;
