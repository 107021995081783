import React from "react";
import styled from "styled-components";

const ModalBackgroundWrapper = styled.div`
  // Fullscreen Positioning
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  // Background
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  // Centering
  padding: 4em;
  overflow: scroll;
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    padding: 2em 1em;
  }
`;

const ModalBackground = ({ onClick, children }) => (
  <ModalBackgroundWrapper onClick={onClick}>{children}</ModalBackgroundWrapper>
);

export default ModalBackground;
