import { formatDistanceToNowStrict } from "date-fns";
import { get, startCase } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { TReviewType } from "@upsolve/shared/dist/types";
import FacebookRecommendationIcon from "../../../static/images/icons/facebook-recommends.png";
import viewReviewUrl from "./viewReviewUrl";
import ImageInContext from "../Media/ImageInContext";
import {stringUtils} from '@upsolve/shared';

const ReviewTile = React.memo(({ hideReviewDate = false, review, setReviewInView, showReviewFully = false }) => {
  if (review.type === TReviewType.GOOGLE) {
    return (
      <StyledReview className="review google" showReviewFully={showReviewFully}>
        <div className="review__reviewer">
          <div className="reviewer-picture">
            <ImageInContext alt={review.reviewerName} src={review.reviewerPhotoUrl} />
          </div>
          <span className="reviewer-name">{startCase(review.reviewerName)}</span>
          <div className="review__rating">
            <span className="stars">{Array(review.rating).fill("★", 0, review.rating).join("")}</span>{" "}
            {!hideReviewDate && <>{formatDistanceToNowStrict(new Date(review.reviewAt))} ago</>}
          </div>
        </div>
        <div className="review__text" onClick={() => !showReviewFully && setReviewInView && setReviewInView(review)}>
          {showReviewFully ? review.reviewText : stringUtils.ellipseString(review.reviewText, 280, "... Read More")}
        </div>
        <span className="link-to-reviews google" onClick={() => viewReviewUrl(review.type)}>
          Read more Google reviews ⇾
        </span>
      </StyledReview>
    );
  }
  if (review.type === TReviewType.FACEBOOK) {
    return (
      <StyledReview className="review facebook" showReviewFully={showReviewFully}>
        <div className="review__reviewer">
          {get(review, "reviewerImage.image.image.fluid") && (
            <div className="reviewer-picture blur">
              <ImageInContext alt="Facebook User" fluid={get(review, "reviewerImage.image.image.fluid")} />
            </div>
          )}
          <span className="reviewer-name facebook">Facebook Reviewer</span>
          <div className="review__rating">
            <img src={FacebookRecommendationIcon} alt="recommends" /> Recommended{" "}
            {!hideReviewDate && <>{formatDistanceToNowStrict(new Date(review.reviewAt))} ago</>}
          </div>
        </div>
        <div className="review__text" onClick={() => setReviewInView && setReviewInView(review)}>
          {showReviewFully ? review.reviewText : stringUtils.ellipseString(review.reviewText, 280, "... Read More")}
        </div>
        <span className="link-to-reviews facebook" onClick={() => viewReviewUrl(review.type)}>
          Read more Facebook reviews ⇾
        </span>
      </StyledReview>
    );
  }
  return null;
});

const StyledReview = styled.div`
  width: 450px;
  min-width: 450px;
  height: 180px;
  min-height: 180px;
  background: ${(props) => props.theme.colors.white[900]};
  border-radius: 8px;
  border: 3px solid ${(props) => props.theme.colors.brand[800]};
  margin: 0 0.5em;
  padding: 1em 1em 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    padding: 0 2px 6px 2px;
    &:last-of-type {
      padding: 0 2px;
    }
  }
  .review__reviewer {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray[500]};
    display: flex;
    align-items: center;
    .reviewer-name {
      width: 100%;
      &.facebook {
        width: 240px;
      }
    }
    .reviewer-picture {
      height: 32px;
      min-height: 32px;
      width: 32px;
      min-width: 32px;
      margin-right: 6px;
      border-radius: 16px;
      overflow: hidden;
      background: ${(props) => props.theme.colors.white[500]};
      img {
        height: 100%;
        width: auto;
      }
      &.blur {
        & > div {
          min-height: 100%;
        }
        img {
          filter: blur(3px);
        }
      }
    }
  }
  .review__rating {
    width: 100%;
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray[500]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    .stars {
      color: ${(props) => props.theme.colors.orange[500]};
      margin-right: 4px;
    }
    & > img {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
  .review__text {
    width: 100%;
    line-height: 125%;
    font-size: 13px;
    color: ${(props) => props.theme.colors.gray[500]};
    overflow: hidden;
    cursor: pointer;
  }
  .link-to-reviews {
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: ${(props) => props.theme.colors.brand[500]};
    text-align: right;
    text-decoration: underline;
    &.facebook,
    &.google {
      svg path {
        fill: ${(props) => props.theme.colors.white[100]};
      }
    }
    svg {
      height: 11px;
      width: 11px;
      position: relative;
      top: 2px;
    }
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  ${({ showReviewFully }) => {
    if (showReviewFully) {
      return css`
        height: auto;
        min-height: auto;
        width: 100%;
        min-width: 100%;
        margin: 0.75em 0;
      `;
    }
  }}
`;

export default ReviewTile;
