import { TReviewType } from "@upsolve/shared/dist/types";
import Bowser from "bowser";
import { get } from "lodash";

export default function viewReviewUrl(reviewType: TReviewType): void {
  const browserProps = Bowser.parse(window.navigator.userAgent);
  if (reviewType === TReviewType.FACEBOOK) {
    window.open("https://www.facebook.com/upsolvebankruptcy/reviews/", "_blank");
  }
  if (reviewType === TReviewType.GOOGLE) {
    if (get(browserProps, "platform.type") === "mobile") {
      window.open("https://g.co/kgs/U3Ke1m", "_blank");
    } else {
      window.open(
        "https://www.google.com/search?ei=7O1oX6KfGonAytMP1oKkKA&q=upsolve+nyc&oq=upsolve+nyc&gs_lcp=CgZwc3ktYWIQAzICCAA6BAgAEEc6BAgAEEM6BggAEBYQHlCiGljmHWDmHmgAcAJ4AIABQ4gB9wGSAQE0mAEAoAEBqgEHZ3dzLXdpesgBCMABAQ&sclient=psy-ab&ved=0ahUKEwiixbPD7frrAhUJoHIEHVYBCQUQ4dUDCA0&uact=5#lrd=0x89c25a4fbebde7fd:0x494443fff411eea3,1",
        "_blank"
      );
    }
  }
}
